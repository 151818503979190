<template>
  <page-layout>
    <template #breadcrumb>
      <el-breadcrumb-item :to="{ name: 'deliveryManagement' }">
        {{ $t('routes.deliveryManagement') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{ $t('delivery.receivingReturningDocumentsOverview') }}
      </el-breadcrumb-item>
    </template>
    <template v-if="currentTask" #headerSide>
      <div class="d-flex align-items-center">
        <RequestsButtonContainer
          v-if="currentTask && currentTask.businessId"
          v-loading="taskLoading"
          class="m-1"
          :source-business-id="currentTask.businessId"
          :task-id="currentTask.id"
          :target-businesses="[currentTask.delivery.document.supplier.id, currentTask.businessId]"
        />
        <el-pagination :page-count="totalCount" :current-page.sync="currentPage" class="text-center" />
      </div>
    </template>
    <div v-if="loading" class="h-100 w-100 d-flex justify-content-center">
      <div v-loading="loading" />
    </div>
    <div v-else-if="!totalCount" class="text-center d-flex flex-column align-items-center justify-content-center">
      <div class="alert alert-info fw-bold my-5" role="alert" style="width: 500px" dir="ltr">
        Congrats, you finished all the tasks!!!
      </div>
    </div>
    <div v-else-if="currentTask" class="row mt7">
      <div class="col-md-7" style="max-height: 90vh; overflow-y: auto">
        <div class="card border-0 mb-5">
          <div class="card-body d-flex flex-row align-items-center py-2">
            <div style="width: 120px">
              <b>{{ $t('delivery.goodsExchangeRecording.headers.documentDetails') }}</b>
            </div>
            <div class="mx-4">
              <small class="text-muted">
                {{ $t('commons.supplier') }}
              </small>
              <div>{{ currentTask.delivery.document.supplier.name }}</div>
            </div>
            <div class="mx-4">
              <small class="text-muted">
                {{ $t('delivery.goodsExchangeRecording.headers.documentType') }}
              </small>
              <div>{{ $t(`document.exports.schema.type.shortName.${currentTask.delivery.document.type}`) }}</div>
            </div>
            <div class="mx-4">
              <small class="text-muted">
                {{ $t('delivery.goodsExchangeRecording.headers.documentNumber') }}
              </small>
              <div>{{ currentTask.delivery.document.documentNumber }}</div>
            </div>
            <div class="mx-4">
              <small class="text-muted">
                {{ $t('delivery.goodsExchangeRecording.headers.documentDate') }}
              </small>
              <div>{{ dateFormatter(currentTask.delivery.document.issueDate) }}</div>
            </div>
          </div>
        </div>
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="mb-5" />
        <el-form ref="form" :model="formModel" :show-message="false">
          <Table
            v-show="activeTab === 0"
            v-loading="deliveriesLoading"
            :data="deliveredTableData"
            :columns="columns"
            show-index
            :hover="false"
            expandable
            custom-class="deliveries-table"
          >
            <template #header-supplierDeliveredQuantity="{ column: { header } }">
              <div>
                {{ header }}
                <small class="d-block text-muted">
                  {{ $t('delivery.goodsExchangeRecording.headers.supplierDocumentation') }}
                </small>
              </div>
            </template>
            <template #header-restaurantDeliveredQuantity="{ column: { header } }">
              <div>
                {{ header }}
                <small class="d-block text-muted">
                  {{ $t('delivery.goodsExchangeRecording.headers.restaurantDocumentation') }}
                </small>
              </div>
            </template>
            <template #cell-restaurantDeliveredQuantity="{ rowData: { productId, associatedItems } }">
              <el-form-item :prop="`deliveredQuantities.${productId}`" :rules="rules.quantity" class="m-0">
                <el-input
                  v-model.number="formModel.deliveredQuantities[productId]"
                  type="number"
                  size="mini"
                  :style="{ width: '70%' }"
                  @change="associatedItems ? updateNested(productId, associatedItems) : null"
                />
              </el-form-item>
            </template>
            <template #expandable-content="{ rowData: { productId: parentProductId, associatedItems } }">
              <Table :data="associatedItems" :columns="nestedTableColumns" :hover="false" class="nested-table">
                <template #cell-restaurantDeliveredQuantity="{ rowData: { productId } }">
                  <el-form-item
                    :prop="`deliveredQuantities.${parentProductId}*${productId}`"
                    :rules="rules.quantity"
                    class="m-0"
                  >
                    <el-input
                      v-model.number="formModel.deliveredQuantities[`${parentProductId}*${productId}`]"
                      type="number"
                      size="mini"
                      :style="{ width: '70%' }"
                    />
                  </el-form-item>
                </template>
              </Table>
            </template>
          </Table>

          <Table
            v-show="activeTab === 1"
            v-loading="deliveriesLoading"
            :data="returnedTableData"
            :columns="columns"
            show-index
            :hover="false"
            expandable
            custom-class="deliveries-table"
          >
            <template #header-supplierDeliveredQuantity="{ column: { header } }">
              <div>
                {{ header }}
                <small class="d-block text-muted">
                  {{ $t('delivery.goodsExchangeRecording.headers.supplierDocumentation') }}
                </small>
              </div>
            </template>
            <template #header-restaurantDeliveredQuantity="{ column: { header } }">
              <div>
                {{ header }}
                <small class="d-block text-muted">
                  {{ $t('delivery.goodsExchangeRecording.headers.restaurantDocumentation') }}
                </small>
              </div>
            </template>
            <template #cell-restaurantDeliveredQuantity="{ rowData: { productId, associatedItems } }">
              <el-form-item :prop="`returnedQuantities.${productId}`" :rules="rules.quantity" class="m-0">
                <el-input
                  v-model.number="formModel.returnedQuantities[productId]"
                  type="number"
                  size="mini"
                  :style="{ width: '70%' }"
                  @change="associatedItems ? updateNested(productId, associatedItems) : null"
                />
              </el-form-item>
            </template>
            <template #expandable-content="{ rowData: { productId: parentProductId, associatedItems } }">
              <Table :data="associatedItems" :columns="nestedTableColumns" :hover="false" class="nested-table">
                <template #cell-restaurantDeliveredQuantity="{ rowData: { productId } }">
                  <el-form-item
                    :prop="`returnedQuantities.${parentProductId}*${productId}`"
                    :rules="rules.quantity"
                    class="m-0"
                  >
                    <el-input
                      v-model.number="formModel.returnedQuantities[`${parentProductId}*${productId}`]"
                      type="number"
                      size="mini"
                      :style="{ width: '70%' }"
                    />
                  </el-form-item>
                </template>
              </Table>
            </template>
          </Table>
        </el-form>
        <div class="py-4 bg-white">
          <Button type="secondary" class="mx-2" @click="selectedCreateType = TAB_TO_CREATE_TYPE[activeTab]">
            <PlusIcon />
            {{
              activeTab === 0
                ? $t('delivery.goodsExchangeRecording.actions.createDeliveredProduct')
                : $t('delivery.goodsExchangeRecording.actions.createReturnedProduct')
            }}
          </Button>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <Button type="primary" @click="handleUpdate">
            {{ $t('saveAndSubmit') }}
          </Button>
        </div>
      </div>

      <div class="col-md-5">
        <pdf-viewer :src="currentTask.delivery.document.filePathUrl" />
      </div>
    </div>
    <AddDeliveryItemModal
      v-if="selectedCreateType"
      :products="supplierProducts"
      :type="selectedCreateType"
      @close="selectedCreateType = null"
      @item-added="addDelivery($event)"
    />
  </page-layout>
</template>

<script type="text/javascript">
import { ref, computed, watch, reactive, getCurrentInstance } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { omit } from 'ramda';

import { Tabs, Table, Button } from '@/modules/core';
import { PlusIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { PdfViewer } from '@/modules/core/components/PdfViewer';
import { useProductsNew } from '@/modules/product/compositions/products';
import { useCompleteTask } from '@/modules/tasks';
import RequestsButtonContainer from '@/modules/tasks/task/components/RequestsButtonContainer';
import { useTenancy } from '@/modules/auth';

import { DELIVERIES_NEW_QUERY, DELIVERY_PATCH_NEW_MUTATION } from './compositions/delivery';
import { useDeliveryTasks } from './compositions/useDeliveryTasks';
import AddDeliveryItemModal from './components/AddDeliveryItemModal';

const TABLE_HEADERS = {
  SKU: 'sku',
  PRODUCT_NAME: 'productName',
  SUPPLIER_DELIVERED_QUANTITY: 'supplierDeliveredQuantity',
  RESTAURANT_DELIVERED_QUANTITY: 'restaurantDeliveredQuantity',
};

export const ITEM_TYPE = {
  DELIVERED: 'delivered',
  RETURNED: 'returned',
};

export default {
  components: { AddDeliveryItemModal, PdfViewer, Table, Tabs, Button, PlusIcon, RequestsButtonContainer },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const formModel = reactive({
      deliveredQuantities: {},
      returnedQuantities: {},
    });
    const activeTab = ref(0);
    const currentPage = ref(root.$route.query.taskNumber ? Number(root.$route.query.taskNumber) : 1);
    const pagination = computed(() => ({
      businessId: currentTenant.value.id,
      limit: 1,
      offset: currentPage.value - 1,
    }));
    const { tasks, totalCount, loading: taskLoading, refetch: tasksRefetch } = useDeliveryTasks(pagination);
    const { result, loading: deliveriesLoading } = useQuery(
      DELIVERIES_NEW_QUERY,
      () => ({
        businessId: currentTenant.value.id,
        supplierId: tasks.value[0]?.delivery.document.supplier.id,
        date: tasks.value[0]?.delivery.document.issueDate,
      }),
      () => ({
        enabled: !!tasks.value[0],
      })
    );
    const deliveries = computed(() => result.value?.deliveries?.nodes ?? []);
    const delivery = computed(() => {
      return deliveries.value.find((d) => {
        const deliveryDocRefs = d.eventReferences.map(({ documentId }) => documentId);
        return deliveryDocRefs.includes(tasks.value[0]?.delivery.document.id);
      });
    });

    const { products, loading: productsLoading } = useProductsNew(
      computed(() => tasks.value[0]?.delivery.document.supplier.id)
    );
    const { mutate: patchDelivery } = useMutation(DELIVERY_PATCH_NEW_MUTATION);
    const { completeTask } = useCompleteTask();
    const rules = computed(() => ({
      quantity: [
        {
          required: true,
          type: 'number',
          min: 0,
        },
      ],
    }));
    watch(
      delivery,
      (delivery) => {
        const deliveredQuantities = {};
        const returnedQuantities = {};
        delivery?.products?.forEach(({ productId, quantity, items }) => {
          if (quantity >= 0) deliveredQuantities[productId] = quantity;
          else returnedQuantities[productId] = Math.abs(quantity);
          items?.forEach(({ productId: nestedProductId, quantity: nestedQuantity }) => {
            if (nestedQuantity >= 0) deliveredQuantities[`${productId}*${nestedProductId}`] = nestedQuantity;
            else returnedQuantities[`${productId}*${nestedProductId}`] = Math.abs(nestedQuantity);
          });
        });
        formModel.deliveredQuantities = deliveredQuantities;
        formModel.returnedQuantities = returnedQuantities;
      },
      { immediate: true }
    );

    watch(
      totalCount,
      (value) => {
        if (!taskLoading.value && currentPage.value > value) currentPage.value = value || 1;
      },
      { immediate: true }
    );
    watch(
      currentPage,
      () => {
        activeTab.value = 0;
      },
      { immediate: true }
    );

    return {
      loading: computed(() => taskLoading.value || productsLoading.value || deliveriesLoading.value),
      currentPage,
      currentTask: computed(() => tasks.value[0]),
      totalCount,
      taskLoading,
      tasksRefetch,
      delivery,
      deliveriesLoading,
      formModel,
      activeTab,
      tabs: [
        { text: root.$i18n.t('delivery.goodsExchangeRecording.tabs.deliveredProducts') },
        { text: root.$i18n.t('delivery.goodsExchangeRecording.tabs.returnedProducts') },
      ],
      products,
      TAB_TO_CREATE_TYPE: {
        0: ITEM_TYPE.DELIVERED,
        1: ITEM_TYPE.RETURNED,
      },
      selectedCreateType: ref(null),
      patchDelivery,
      completeTask,
      rules,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('sku'),
          key: TABLE_HEADERS.SKU,
          width: '18%',
        },
        {
          header: this.$t('product.product'),
          key: TABLE_HEADERS.PRODUCT_NAME,
        },
        {
          header:
            this.activeTab === 0
              ? this.$t('delivery.goodsExchangeRecording.headers.deliveredQuantity')
              : this.$t('delivery.goodsExchangeRecording.headers.returnedQuantity'),
          key: TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY,
          width: '18%',
        },
        {
          header:
            this.activeTab === 0
              ? this.$t('delivery.goodsExchangeRecording.headers.deliveredQuantity')
              : this.$t('delivery.goodsExchangeRecording.headers.returnedQuantity'),
          key: TABLE_HEADERS.RESTAURANT_DELIVERED_QUANTITY,
          width: '18%',
        },
      ];
    },
    nestedTableColumns() {
      return [{ key: 'empty', width: '110px' }, ...this.columns].map(omit(['header', 'sortCallback']));
    },
    deliveredTableData() {
      return Object.keys(this.formModel.deliveredQuantities)
        .filter((key) => key.split('*').length === 1)
        .map((productId) => {
          const deliveryItem = this.delivery?.products?.find(
            ({ productId: deliveryProductId, quantity }) => deliveryProductId === productId && quantity >= 0
          );
          const product = this.products.find(({ id }) => id === productId);
          const mainItem = {
            productId,
            [TABLE_HEADERS.SKU]: product?.sku,
            [TABLE_HEADERS.PRODUCT_NAME]: product?.name,
            [TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY]: deliveryItem?.quantity ?? 0,
            expandable:
              (deliveryItem?.items && deliveryItem?.items.length > 0) || product?.associatedProducts?.length > 0
                ? true
                : false,
            expandableCustomClass: deliveryItem?.items || product?.associatedProducts?.length > 0 ? 'p-0' : null,
          };

          const associatedItems =
            deliveryItem?.items && deliveryItem?.items.length > 0
              ? deliveryItem?.items.map(({ productId: associatedProductId, quantity }) => {
                  const product = this.products.find(({ id }) => id === associatedProductId);
                  return {
                    productId: associatedProductId,
                    [TABLE_HEADERS.SKU]: product?.sku,
                    [TABLE_HEADERS.PRODUCT_NAME]: product?.name,
                    [TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY]: quantity ?? 0,
                  };
                })
              : product?.associatedProducts?.length > 0
              ? product?.associatedProducts?.map(({ productId: associatedProductId, quantity }) => {
                  const product = this.products.find(({ id }) => id === associatedProductId);
                  return {
                    productId: associatedProductId,
                    [TABLE_HEADERS.SKU]: product?.sku,
                    [TABLE_HEADERS.PRODUCT_NAME]: product?.name,
                    [TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY]: 0,
                    associatedProductQuantity: quantity,
                  };
                })
              : [];

          return {
            ...mainItem,
            associatedItems,
          };
        });
    },
    returnedTableData() {
      return Object.keys(this.formModel.returnedQuantities)
        .filter((key) => key.split('*').length === 1)
        .map((productId) => {
          const deliveryItem = this.delivery?.products?.find(
            ({ productId: deliveryProductId, quantity }) => deliveryProductId === productId && quantity < 0
          );
          const product = this.products.find(({ id }) => id === productId);
          const mainItem = {
            productId,
            [TABLE_HEADERS.SKU]: product?.sku,
            [TABLE_HEADERS.PRODUCT_NAME]: product?.name,
            [TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY]: deliveryItem?.quantity ? Math.abs(deliveryItem?.quantity) : 0,
            expandable:
              (deliveryItem?.items && deliveryItem?.items.length > 0) || product?.associatedProducts?.length > 0
                ? true
                : false,
            expandableCustomClass: deliveryItem?.items || product?.associatedProducts?.length > 0 ? 'p-0' : null,
          };

          const associatedItems =
            deliveryItem?.items && deliveryItem?.items.length > 0
              ? deliveryItem?.items.map(({ productId: associatedProductId, quantity }) => {
                  const product = this.products.find(({ id }) => id === associatedProductId);
                  return {
                    productId: associatedProductId,
                    [TABLE_HEADERS.SKU]: product?.sku,
                    [TABLE_HEADERS.PRODUCT_NAME]: product?.name,
                    [TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY]: quantity ? Math.abs(quantity) : 0,
                  };
                })
              : product?.associatedProducts?.length > 0
              ? product?.associatedProducts?.map(({ productId: associatedProductId, quantity }) => {
                  const product = this.products.find(({ id }) => id === associatedProductId);
                  return {
                    productId: associatedProductId,
                    [TABLE_HEADERS.SKU]: product?.sku,
                    [TABLE_HEADERS.PRODUCT_NAME]: product?.name,
                    [TABLE_HEADERS.SUPPLIER_DELIVERED_QUANTITY]: 0,
                    associatedProductQuantity: Math.abs(quantity),
                  };
                })
              : [];

          return {
            ...mainItem,
            associatedItems,
          };
        });
    },
    supplierProducts() {
      return this.activeTab === 0
        ? this.products.filter(({ id }) => this.deliveredTableData.every(({ productId }) => id !== productId))
        : this.products.filter(({ id }) => this.returnedTableData.every(({ productId }) => id !== productId));
    },
  },
  methods: {
    dateFormatter(ms) {
      return new Date(ms).toLocaleDateString(this.$i18n.locale, options.short);
    },
    async addDelivery(data) {
      this.$set(
        this.TAB_TO_CREATE_TYPE[this.activeTab] === ITEM_TYPE.DELIVERED
          ? this.formModel.deliveredQuantities
          : this.formModel.returnedQuantities,
        data.productId,
        data.quantity
      );

      const product = this.products.find(({ id }) => id === data.productId);
      product?.associatedProducts?.forEach((item) => {
        const updatedAssociatedItemQuantity = data.quantity * item.quantity;
        this.$set(
          this.TAB_TO_CREATE_TYPE[this.activeTab] === ITEM_TYPE.DELIVERED
            ? this.formModel.deliveredQuantities
            : this.formModel.returnedQuantities,
          `${data.productId}*${item.productId}`,
          updatedAssociatedItemQuantity
        );
      });
      this.selectedCreateType = null;
    },
    updateNested(parentProductId, associatedItems) {
      const [tableData, productQuantities] =
        this.TAB_TO_CREATE_TYPE[this.activeTab] === ITEM_TYPE.DELIVERED
          ? [this.deliveredTableData, this.formModel.deliveredQuantities]
          : [this.returnedTableData, this.formModel.returnedQuantities];
      associatedItems.forEach((item) => {
        const parentProductOriginalQuantity = tableData.find(
          ({ productId: deliveryProductId }) => deliveryProductId === parentProductId
        )?.supplierDeliveredQuantity;
        const updatedAssociatedItemQuantity = parentProductOriginalQuantity
          ? (productQuantities[parentProductId] * item.supplierDeliveredQuantity) / parentProductOriginalQuantity
          : productQuantities[parentProductId] * item.associatedProductQuantity;

        this.$set(productQuantities, `${parentProductId}*${item.productId}`, updatedAssociatedItemQuantity);
      });
    },
    async handleUpdate() {
      this.$confirm(this.$i18n.t('commons.areYouSure'), { type: 'warning' })
        .then(async () =>
          this.$refs.form.validate(async (isValid) => {
            if (isValid) {
              const loading = this.$loading();
              const patchDeliveriesProducts = [];
              if (Object.keys(this.formModel.deliveredQuantities).length !== 0) {
                Object.keys(this.formModel.deliveredQuantities)
                  .filter((key) => key.split('*').length === 1 && this.formModel.deliveredQuantities[key] !== 0)
                  .forEach(async (productId) => {
                    const deliveryProductQuantity = this.formModel.deliveredQuantities[productId];
                    const associatedItems = Object.keys(this.formModel.deliveredQuantities)
                      .filter((key) => key.startsWith(`${productId}*`) && !!this.formModel.deliveredQuantities[key])
                      .map((key) => {
                        return {
                          productId: key.split('*')[1],
                          quantity: this.formModel.deliveredQuantities[key],
                        };
                      });

                    if (deliveryProductQuantity)
                      patchDeliveriesProducts.push({
                        productId,
                        quantity: deliveryProductQuantity,
                        items: associatedItems,
                      });
                  });
              }
              if (Object.keys(this.formModel.returnedQuantities).length !== 0)
                Object.keys(this.formModel.returnedQuantities)
                  .filter((key) => key.split('*').length === 1 && this.formModel.returnedQuantities[key] !== 0)
                  .forEach(async (productId) => {
                    const deliveryProductQuantity = this.formModel.returnedQuantities[productId] * -1;
                    const associatedItems = Object.keys(this.formModel.returnedQuantities)
                      .filter((key) => key.startsWith(`${productId}*`) && !!this.formModel.returnedQuantities[key])
                      .map((key) => {
                        return {
                          productId: key.split('*')[1],
                          quantity: this.formModel.returnedQuantities[key] * -1,
                        };
                      });
                    if (deliveryProductQuantity)
                      patchDeliveriesProducts.push({
                        productId,
                        quantity: deliveryProductQuantity,
                        items: associatedItems,
                      });
                  });
              try {
                await this.patchDelivery({ id: this.delivery.id, data: { products: patchDeliveriesProducts } });
                await this.completeTask({ taskId: this.currentTask.id });
                this.$message.success(this.$t('commons.messages.action.success'));
                await this.tasksRefetch();
              } catch (err) {
                this.$message.error(`${err.error} - ${err.reason}`);
              } finally {
                loading.close();
              }
            }
          })
        )
        .catch(() => false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .deliveries-table {
  td {
    padding: 0.25rem 1rem;
    vertical-align: middle;
  }
}
::v-deep .nested-table {
  th {
    padding: 0;
  }
  td {
    background: $secondary;
    &.bg-compare {
      background: #e1e7f9;
    }
  }
}
</style>
